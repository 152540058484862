.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	z-index: 999999999;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.5);

	.container {
		width: 100%;
		padding: 20px;
		display: flex;
		max-width: 350px;
		gap: var(--gap-lg);
		text-align: center;
		position: relative;
		border-radius: 10px;
		flex-direction: column;
		background: var(--white);
		box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

		@media screen and (min-width: 641px) {
			max-width: 400px;
		}

		> h1 {
			font-weight: bold;
			text-transform: none;
			font-size: var(--fs-lg);

			@media screen and (min-width: 641px) {
				max-width: 400px;
			}
		}

		.buttons {
			gap: 15px;
			display: flex;
			justify-content: space-between;

			.cancelBtn,
			.confirmBtn {
				padding: 10px 20px;
				font-size: 16px;
				border-radius: 5px;
				cursor: pointer;
				border: none;
				width: 48%;
			}

			.cancelBtn {
				color: #333;
				background-color: #f0f0f0;
				transition: background-color 0.3s;

				&:hover {
					background-color: #e0e0e0;
				}
			}

			.confirmBtn {
				color: var(--white);
				background-color: var(--primary);
				transition: background-color 0.3s;

				&:hover {
					background-color: #c9302c;
				}
			}
		}
	}
}
