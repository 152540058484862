.background {
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 10000;
	display: flex;
	position: fixed;
	justify-content: center;
	align-items: flex-start;
	background-color: rgba(0, 0, 0, 0.5);
}

.modalContainer {
	padding: 20px;
	border-radius: 10px;
	box-shadow: var(--shadow2);
	max-height: calc(100% - 40px);
	background-color: var(--white);
}

.header {
	display: grid;
	height: 40px;
	margin-bottom: 5px;
	align-content: center;
	grid-template-columns: 40px auto 40px;
	.left {
		display: block;
		width: 35px;
		height: 35px;
		cursor: pointer;
		z-index: 100;

		:hover {
			border-radius: 50%;
			background-color: #dddddd;
		}
	}

	.heading {
		display: flex;
		padding: 0 10px;
		align-items: center;

		h2 {
			font-weight: 600;
			font-family: sans-serif;
			font-size: var(--fs-display);
			color: rgba(15, 20, 25, 0.8);
		}
	}
}
