.card {
	height: 100%;
	overflow: scroll;
}

.cardcontentarea {
	max-width: 100%;
	font-weight: 500;
	line-height: 16px;
}

[contentEditable='true']:empty:not(:focus):before {
	content: attr(data-text);
}

.mainContent {
	display: flex;
	flex-direction: column;

	textarea {
		overflow-x: scroll;
		font-size: var(--fs-para) !important;
	}

	.textarea::-webkit-scrollbar {
		display: none;
	}
}
