.stage__select {
	padding: 10px 0;
	display: flex;
	justify-content: space-between;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.item {
	display: flex;
	gap: 3px;
	font-weight: 550;
	line-height: 27px;
	align-items: center;
	color: rgba(0, 0, 0, 0.85);
	font-size: var(--fs-head);

	.icon {
		width: 23px;
		height: 23px;
		color: var(--black);
	}
	@media (max-width: 600px) {
		font-weight: 550;
		font-size: 14px;
		line-height: 16px;
	}
}

.item.active {
	color: var(--primary);
	.icon {
		color: var(--primary);
	}
}
