//-----------Header------------

.post-header {
	display: flex;
	gap: 10px;
	align-items: center;
}

//-----------Content------------

.cardtextarea {
	margin-top: 15px;
	max-width: 100%;
	height: 140px;

	@media only screen and (min-width: 700px) {
		height: 150px;
	}

	@media only screen and (min-width: 1200px) {
		height: 180px;
	}
}

.actions {
	width: 100%;
	display: flex;
	justify-content: space-between;
	gap: 10px;
	align-items: center;

	.btns {
		display: flex;
		align-items: center;
		gap: 10px;
	}
}

.icon {
	height: 24px;
	width: 24px;
	cursor: pointer;
}

.file {
	display: none;
}

.polls-icon {
	width: 24px;
	height: 24px;
}

.cross {
	position: absolute;
	background: #ffffff;
	border-radius: 50%;
	top: 15px;
	right: 15px;
	height: 20px;
	width: 20px;
	cursor: pointer;
	display: flex;
	z-index: 1;
	box-shadow: 1px 3px 5px 2px rgba(0, 0, 0, 0.6);
	justify-content: center;
	align-items: center;
}

//---------Loader-----------
.loader {
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 5;
	display: flex;
	position: absolute;
	justify-content: center;
	align-items: center;
	background-color: rgba($color: #f1f1f1, $alpha: 0.3);
	.loaderIcon {
		width: 50px;
		height: 50px;
		border-radius: none;
		color: var(--primary);
	}
}

//------ImagesList--------
.images {
	display: flex;
	overflow-y: auto;
}

.swiper {
	width: 100%;
	height: 100%;
}
.image__container {
	overflow: hidden;
	margin: 5px auto;
	border-radius: 8px;
	background-color: rgba(0, 0, 0, 0.2);
}

.image__wrapper {
	position: relative;
	max-height: 200px;

	img {
		height: 120px;
		object-fit: cover;

		@media screen and (min-width: 641px) and (orientation: landscape) {
			height: 140px;
		}

		@media screen and (min-width: 1025px) {
			height: 320px;
		}

		@media screen and (min-width: 1281px) {
			height: 350px;
		}
	}
}

.image {
	width: 100%;
	overflow: hidden;
	object-fit: cover;
}

.ImageCont {
	display: flex;
	width: auto;
	height: 340px;
	align-items: center;
	justify-content: center;
}

//---------PostIcon--------

.postIcon {
	position: absolute;
	left: 50%;
	z-index: inherit;
	bottom: -29px;
	display: flex;
	padding: 5px;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	transform: translateX(-50%);
	background-color: var(--white);
	box-shadow: 2px 2px 8px 3px rgba(0, 0, 0, 0.35);

	svg {
		width: 50px;
		height: 50px;
		padding: 10px;
	}
}

// polls preview
.pollsPreview {
	width: 100%;
	overflow-y: auto;
	max-height: 200px;
	padding: 8px;
	border: 1px solid lightgray;
	border-radius: 5px;
	margin-bottom: 8px;

	.head {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		div {
			display: flex;
			gap: 10px;
		}

		.removeBtn {
			color: rgba($color: #ff0000, $alpha: 0.6);
			font-size: 1.3rem;
		}
	}

	.options {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 7px;
		margin-top: 8px;

		.option {
			width: 100%;
			border: 1px solid lightgray;
			padding: 7px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 5px;
			border-radius: 3px;

			.icon {
				width: 15px;
				height: 15px;
				color: rgb(142, 142, 142);
			}
		}
	}

	.expiry {
		font-size: 1.2rem;
		opacity: 0.6;
		margin-top: 8px;
		width: 100%;
		text-align: right;
	}
}
