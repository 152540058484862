@font-face {
	font-family: CipherSchools_font;
	src: url(../assets/fonts/DESIB___.ttf);
}

body {
	background-color: var(--bg-main);
}

*::-webkit-scrollbar {
	width: 0;
	height: 1rem;
}

*::-webkit-scrollbar-track {
	background-color: var(--primary-shade);
}

*::-webkit-scrollbar-thumb {
	background-color: var(--scrollbar);
}

*::-webkit-scrollbar-thumb:hover {
	background-color: var(--scrollbar-hover);
}

*::selection {
	background-color: var(--primary);
	color: var(--primary-text);
}

*::placeholder {
	color: inherit;
	font-weight: 400;
	opacity: 0.6;
	line-height: inherit;
	text-transform: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

span {
	display: -webkit-inline-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.MuiTablePagination-menuItem {
	&,
	* {
		font-size: 1.6rem !important;
	}
}

.verificatoionModal {
	width: calc(100% - 15px);
	padding: 1em;
	height: auto;
	/* portrait tablets */
	@media only screen and (min-width: 640px) {
		/* hi4 */
		width: 30vw;
		padding: 1.5em;
		height: auto;
	}

	/* big laptop screens */
	@media only screen and (min-width: 1281px) {
		/* hi7 */
		width: 30%;
	}
}

.postModal {
	width: calc(100% - 15px);
	padding: 1em;
	height: auto;
	/* portrait tablets */
	@media only screen and (min-width: 640px) {
		/* hi4 */
		width: 60vw;
		padding: 1.5em;
		height: auto;
	}

	/* big laptop screens */
	@media only screen and (min-width: 961px) {
		/* hi7 */
		width: 50vw;
	}

	/* big laptop screens */
	@media only screen and (min-width: 1025px) {
		/* hi7 */
		width: 40%;
	}

	/* big laptop screens */
	@media only screen and (min-width: 1500px) {
		/* hi7 */
		width: 30%;
	}
}

.boradcastModal-overlay {
	padding: 0px !important;
	margin: 0px !important;
	height: 110vh !important;
	bottom: 0 !important;

	@media only screen and (min-width: 640px) {
		padding-top: 8vh !important;
		height: auto;
	}
}

.boradcastModal {
	width: 100%;
	height: 100%;
	padding: 1em;
	border-radius: 0px;
	animation-duration: 0.5s;
	animation-name: modalAnimation;

	/* portrait tablets */
	@media only screen and (min-width: 640px) {
		/* hi4 */
		width: 60%;
		height: auto;
		padding: 1.5em;
		animation: none;
		border-radius: 20px;
	}
	@media only screen and (min-width: 800px) {
		/* hi4 */
		width: 45%;
	}

	/* big laptop screens */
	@media only screen and (min-width: 1080px) {
		/* hi7 */
		width: 40%;
	}

	/* big laptop screens */
	@media only screen and (min-width: 1281px) {
		/* hi7 */
		width: 35%;
	}
}

.imageViewer-overlay {
	width: 100vw;
	height: 100vh;
	top: 0;
	height: auto;

	.div {
		height: 10vh;
	}
}

.imageViewer {
	width: 100%;
	padding: 1em;
	height: 100vh;
	border-radius: 0px;
	animation-duration: 0.5s;
	transition: all 0.1s ease-in-out;
	background-color: rgb(25, 25, 25);
}

.signinModal {
	width: calc(100% - 15px);
	padding: 1em;
	height: auto;
	/* portrait tablets */
	@media only screen and (min-width: 641px) {
		/* hi4 */
		width: 60%;
		padding: 1.5em;
	}

	/* big laptop screens */
	@media only screen and (min-width: 1281px) {
		/* hi7 */
		width: 40%;
	}
}

.createCommunityModal {
	width: calc(100% - 30px);
	padding: 1em;
	/* portrait tablets */
	@media only screen and (min-width: 641px) {
		/* hi4 */
		width: 60%;
		padding: 1.5em;
	}

	/* small laptop screens */
	@media only screen and (min-width: 981px) {
		/* hi7 */
		width: 45%;
	}

	/* small laptop screens */
	@media only screen and (min-width: 1080px) {
		/* hi7 */
		width: 40%;
	}

	/* big laptop screens */
	@media only screen and (min-width: 1281px) {
		/* hi7 */
		width: 35%;
	}
}

.userNameModal {
	width: 320px;
}

// modal animation
@keyframes modalAnimation {
	from {
		transform: translateY(-200px);
		opacity: 0.7;
	}

	to {
		transform: translateY(0px);
		opacity: 1;
	}
}

.postIcon {
	position: fixed;
	display: flex;
	padding: 5px;
	right: -10px;
	bottom: 70px;
	z-index: 1;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	transform: translateX(-50%);
	background-color: var(--white);
	box-shadow: 5px 2px 8px 2px rgba(0, 0, 0, 0.7);

	svg {
		width: 50px;
		height: 50px;
		padding: 8px;
		padding-left: 3px;
		color: var(--primary);
	}

	@media (min-width: 600px) {
		display: none;
	}
}
