.fieldset {
	display: flex;
	width: 100%;
	padding: 0px;
	margin: 10px 0;
	background-color: var(--white);
	border-radius: var(--radius-md);

	.inputCont {
		display: flex;
		width: 100%;
		height: 35px;
		padding: 5px 10px;
		border: 1px solid rgba(30, 30, 30, 0.25);
	}

	.input {
		width: calc(100% - 50px);
		text-transform: none;
		background-color: var(--white);
	}
}

.icon {
	height: 20px;
	width: 20px;
	color: var(--primary);
}

.actionBtns {
	display: flex;
	gap: 10px;
	top: 50%;
	right: 10px;
	max-width: 50px;
	align-items: center;
	position: absolute;
	transform: translateY(-50%);
	button {
		display: flex;
	}
}

.container {
	height: 4rem;
	width: 100%;
	padding: 0 calc(var(--padding) / 1.5);
	background-color: var(--bg-sub);
	border-radius: var(--radius-md);
	font-size: var(--fs-para);
	color: var(--fc-para);
	font-weight: 550;
	transition: 0.15s ease;
	text-transform: none;
	line-height: 1.4;

	&:focus {
		box-shadow: 0 0 0 0.125rem var(--primary);
	}
}

.options {
	position: absolute;
	// top: 100%;
	left: 0;
	width: 100%;
	max-height: 222px;
	overflow: hidden auto;
	background-color: var(--white);
	box-shadow: var(--shadow), var(--shadow);
	border-radius: var(--radius-md);
	padding: 0.75rem 0;
	margin-top: 0.75rem;
	display: none;
	flex-direction: column;
	z-index: 20;

	&.opened {
		display: flex;
	}

	[class~='option'] {
		display: flex;
		align-items: center;
		gap: calc(var(--padding) / 2);
		padding: 0.4rem 1.6rem;
		cursor: pointer;
		user-select: none;

		&:hover {
			background-color: var(--bg-sub);
		}
	}

	[class~='image'] {
		height: 2.75rem;
		width: 2.75rem;
		background-color: var(--bg-sub);
		border-radius: 5rem;
		overflow: hidden;
	}

	[class~='text'] {
		color: var(--fc-title);
		display: -webkit-box;
		-webkit-box-orient: vertical;
		line-clamp: 1;
		-webkit-line-clamp: 1;
		overflow: hidden;
		flex: 1;

		svg {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 0;
			height: 70%;
			width: auto;
			color: var(--primary);
		}
	}
}
