.container {
	display: flex;
	max-width: 320px;
	width: fit-content;
	gap: var(--gap-md);
	align-items: center;
	flex-direction: column;
	justify-content: center;

	@media screen and (min-width: 961px) {
		max-width: 350px;
	}

	.popupImage {
		width: 300px;
		height: auto;
		border-radius: 10px;

		@media screen and (min-width: 961px) {
			width: 350px;
		}
	}

	> h3 {
		text-transform: none;
		font-size: var(--fs-md);
		font-weight: var(--fw-medium);

		@media screen and (min-width: 961px) {
			font-size: var(--fs-lg);
		}
	}

	> p {
		text-align: justify;
		font-size: var(--fs-xs);

		@media screen and (min-width: 961px) {
			font-size: var(--fs-sm);
		}
	}

	.appsContainer {
		display: flex;
		gap: var(--gap-md);
		align-items: center;
		margin-top: var(--gap-md);

		> a {
			display: flex;
			cursor: pointer;
			gap: var(--gap-sm);
			align-items: center;
			border-radius: 10px;
			background-color: black;
			color: var(--second-white);
			border: 1px solid var(--white);
			justify-content: space-between;
			padding: var(--gap-sm) var(--gap-md);

			@media screen and (min-width: 961px) {
				padding: var(--gap-sm) var(--gap-md);
			}

			@media screen and (min-width: 1025px) {
				gap: var(--gap-sm);
			}

			.downloadIcon {
				color: var(--white);
				font-size: var(--fs-4xl);
			}

			> p {
				line-height: 20px;
				color: var(--white);
				font-size: var(--fs-sm);

				@media screen and (min-width: 641px) {
					font-size: var(--fs-md);
				}

				span {
					color: var(--white);
					font-size: var(--fs-xs);
				}
			}
		}
	}

	.logout {
		cursor: pointer;
		color: var(--primary);
		font-size: var(--fs-md);
	}
}
