.container {
	display: flex;
	width: 300px;
	height: 200px;
	text-align: left;
	flex-direction: column;

	h2 {
		text-align: left;
		font-weight: 550;
		font-size: var(--fs-title);
	}

	p {
		line-height: 20px;
		font-size: var(--fs-para);
	}

	.loadingIcon {
		display: flex;
		width: 100%;
		justify-content: center;
	}

	.imageContainer {
		display: flex;
		width: 100px;
		height: 100px;
		margin: auto;
	}

	.resendBtn {
		font-weight: 500;
		text-align: center;
		color: var(--primary);
		font-size: var(--fs-small);
	}
}
