.header {
	display: flex;
	position: sticky;
	top: 0;
	z-index: 1000;
	margin: 0 auto;
	align-items: center;
	padding: 8px 12px;
	max-width: 100vw;
	backdrop-filter: blur(10px);
	background-color: var(--white);
	-webkit-backdrop-filter: blur(10px);
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);

	@media (min-width: 641px) {
		padding: 12px;
	}

	@media (min-width: 1281px) {
		display: grid;
		padding: 28px auto 0;
		padding-left: calc((100vw - var(--page-size)) / 2);
		padding-right: calc((100vw - var(--page-size)) / 2);
		grid-template-columns: var(--left-sidebar-width) auto;
	}
}

.main {
	@media (min-width: 481px) and (orientation: landscape) {
		margin: 0px auto;
	}

	@media (min-width: 641px) {
		display: grid;
		max-width: var(--page-size);
		grid-template-columns: var(--left-sidebar-width) auto;
	}

	@media (min-width: 1025px) {
		grid-template-columns: var(--left-sidebar-width) auto;
	}

	.sidebar {
		display: none;
		top: 0;
		width: 100%;
		padding: 0 15px;
		padding-top: 60px;
		position: sticky;
		overflow-y: auto;
		max-height: var(--max-height);

		@media (min-width: 641px) and (orientation: portrait) {
			display: block;
		}

		@media (min-width: 961px) and (orientation: landscape) {
			display: block;
		}
	}
}

.container {
	display: flex;

	.content {
		top: 0;
		width: 100%;
		padding: 0;
		margin: 0px auto;
		overflow: hidden;
		position: sticky;
		max-width: var(--content);

		> article {
			--profile-header: 215px;
			@media (max-width: 600px) {
				--profile-header: 205px;
			}
			max-height: calc(var(--max-height) - var(--profile-header));
		}
	}

	.sidebar {
		display: none;
		width: 100%;
		top: 0;
		overflow-y: auto;
		position: sticky;
		padding: 10px 5px;
		max-height: var(--max-height);
		max-width: var(--feature-content);
		@media (min-width: 1025px) {
			display: block;
		}
	}
}

.profileHeader {
	display: flex;
	margin-bottom: 5px;
	box-sizing: border-box;
	flex-direction: column;
	padding: 10px var(--global-pad);
	max-height: var(--profile-header);
	background-color: var(--card-background);
}

.follow {
	min-width: 100%;
	display: flex;
	flex-direction: column;
	gap: 15px;
	margin: 20px 0;
}
