.sf-right-side-btns {
	display: flex;
	align-items: center;
}

.sf-textfield-row {
	display: flex;
	gap: 10px;
	margin: 10px 0;
	flex-direction: column;
	justify-content: center;
}

.sf-dropDown {
	display: flex;
	align-items: center;
}

.sf-input {
	background-color: var(--bg-ackground);
}

.sf-textfield {
	padding: 10px;
	border-radius: 8px;
	background-color: var(--bg-background);
}

.sf-textfield,
.sf-textfield input {
	line-height: 20px;
	color: var(--heading);
	border: none;
	outline: none;
}

.sf-msg-cont {
	width: 100%;
	height: 100px;
	padding: 10px;
	line-height: 20px;
	border-radius: 8px;
	color: var(--heading);
	background-color: var(--bg-background);
}

.sf-btns-cont {
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: flex-end;
	margin-top: 10px;
}

.sf-msg-btn {
	padding: 0.5rem 1rem;
	font-size: 14px;
	color: white;
	background-color: var(--primary);
	cursor: not-allowed;
	border-radius: 5px;
	border: none;
	outline: none;
	transition: 0.15s ease;
	opacity: 0.5;
}

.sf-btn-active {
	opacity: 1;
	cursor: pointer;
}

.sf-btn-active:hover {
	opacity: 0.85;
}

@media screen and (min-width: 601px) {
	.sf-textfield-row,
	.sf-main-cont,
	.sf-btns-cont {
		gap: 15px;
	}

	.sf-msg-btn,
	.sf-msg-btn1 {
		font-size: 13px;
	}
}

@media screen and (min-width: 801px) {
	.sf-textfield,
	.sf-msg-cont {
		padding: 13px;
	}

	.sf-msg-btn,
	.sf-msg-btn1 {
		font-size: 14px;
		height: 35px;
	}
}

@media screen and (min-width: 1001px) {
	.sf-textfield-row,
	.sf-main-cont,
	.sf-btns-cont {
		gap: 20px;
	}
}

@media screen and (min-width: 1201px) {
	.sf-textfield,
	.sf-msg-cont {
		padding: 10px 16px;
	}
}

@media screen and (min-width: 1401px) {
}
