.modal {
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 10000;
	display: flex;
	position: fixed;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
	@media screen and (min-width: 600px) {
		display: none;
	}

	.banner {
		display: flex;
		flex-direction: column;
		gap: 10px;
		background-color: var(--bg-main);
		padding: 25px;
		border-radius: 10px;
		box-shadow: var(--shadow2);
		max-height: calc(100% - 40px);
		background-color: var(--white);
		width: 350px;
		@media screen and (min-width: 400px) {
			width: 400px;
		}

		.text {
			display: flex;
			flex-direction: column;
			gap: 10px;

			div {
				display: flex;
				align-items: center;
				gap: 10px;
				img {
					height: 32px;
					width: 32px;
					object-fit: contain;
				}
				h1 {
					color: #54595e;
					font-size: 20px;
				}
			}
			p {
				font-size: 13px;
				color: #54595e;
			}
		}

		a {
			padding: 1rem 0.5rem;
			border-radius: 5px;
			color: white;
			background-color: var(--primary);
			button {
				color: white;
				text-align: center;
				width: 100%;
			}
		}
	}

	.icon {
		position: absolute;
		top: 10px;
		right: 10px;
		background-color: #e5e5e5;
		color: #4f4f4f;
		border-radius: 50%;
	}
}
