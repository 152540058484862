.header {
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: space-between;

	section {
		display: flex;
		gap: 5px;
	}
	h2 {
		font-weight: 600;
		line-height: 24px;
		font-size: var(--fs-display);
	}
	h3 {
		font-weight: 600;
		font-size: var(--fs-para);
		line-height: 27px;
		color: rgba(30, 30, 30, 0.7);
		text-transform: lowercase;
	}

	@media (max-width: 600px) {
		h2 {
			font-weight: 550;
			font-size: var(--fs-large);
		}
		h3 {
			font-size: var(--fs-small);
			line-height: 16px;
		}
	}
}

.editBtn {
	padding: 5px 10px;
	border-radius: 10px;
	font-size: var(--fs-title);
	svg {
		width: 18px;
	}
}

.stats {
	display: flex;
	align-items: center;
	margin: 10px 0 15px;
	justify-content: space-between;

	a {
		text-align: center;
	}

	h2 {
		font-weight: 550;
		font-size: var(--fs-large);
		line-height: 27px;
	}
	h3 {
		font-weight: 500;
		font-size: var(--fs-title);
		line-height: 18px;
	}

	@media (max-width: 600px) {
		h2 {
			font-size: var(--fs-head);
		}
		h3 {
			font-size: var(--fs-para);
		}
	}
}

.avatar {
	@media (max-width: 600px) {
		width: 50px;
		height: 50px;
	}
}

.pencil {
	cursor: pointer;
	height: 25px;
	width: 25px;
	color: #fff;
	border-radius: 50%;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	bottom: 0px;
	left: 30px;
	z-index: 1;
	background-color: rgba(0, 0, 0, 0.5);
}

.btn {
	border-radius: 5px;
	padding: 5px 10px;
}
