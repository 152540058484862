*:disabled,
.disabled {
	opacity: 0.8;
	cursor: default;
}

.EmojiPickerReact {
	position: absolute !important;
	z-index: 1000 !important;
	bottom: -20% !important;
}
