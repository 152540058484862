.ImageCont {
	display: flex;
	width: 100%;
	height: 340px;
	min-width: 200px;
	align-items: center;
	justify-content: center;
}

.image {
	opacity: 1;
	z-index: 1;
	transition: 0.15s ease;
	object-fit: cover;
	transform: scale(1);
}

.imageLoading,
.imageError {
	opacity: 0;
	transform: scale(0);
}

.error {
	z-index: 1;
	transform: scale(0);
	transition: 0.15s ease;
	opacity: 0;
	font-weight: 550;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	user-select: none;
}

.errorError {
	transform: scale(1);
	opacity: 1;
}

.loader {
	position: absolute;
	// top: 50%;
	// left: 50%;
	// transform: translate(-50%, -50%) scale(0);
	z-index: 3;
	opacity: 0;
	transition: 0.15s ease;
}

.loaderLoading {
	opacity: 1;
	// transform: translate(-50%, -50%) scale(1);
}
