.polls {
	width: 100%;
	overflow-y: auto;
	@media (max-height: 350px) {
		height: 50px !important;
	}
	@media (max-height: 550px) {
		height: 230px !important;
	}

	label {
		display: flex;
		margin-bottom: 5px;
		.remove {
			color: rgb(213, 57, 57);
			font-size: 1.2rem;
			cursor: pointer;
			margin-left: 4px;
		}
	}

	.quesionInput {
		padding: 8px;
		width: 100%;
		border: 1px solid lightgray;
		border-radius: 5px;
	}

	.inputWordCount {
		font-size: 1.1rem;
		width: 100%;
		text-align: right;
		opacity: 0.6;
	}

	.options {
		width: 100%;
		margin-top: 15px;
		display: flex;
		flex-direction: column;
		gap: 12px;

		.option {
			display: flex;
			flex-direction: column;

			input {
				padding: 8px;
				width: 100%;
				border: 1px solid lightgray;
				border-radius: 5px;
			}
		}
	}

	.addBtn {
		padding: 5px;
		background-color: transparent;
		border: 1px solid var(--primary);
		color: var(--primary);
		border-radius: 5px;
	}

	.duration {
		margin-top: 10px;
		padding-left: 2px;
		padding-right: 2px;
		// color: black;

		.color-input {
			color: black;
		}
		// .input-field-select {
		// 	border: 1px solid red !important;
		// }
		.control-color {
			border: 1px solid var(--primary);
			border-radius: 2px;
			box-shadow: none;
		}
		.color-selected {
			background-color: rgba(244, 80, 70, 0.1);
			color: var(--primary);
			// color: black;
		}
	}

	.action {
		width: 100%;
		display: flex;
		gap: 10px;
		margin-top: 18px;
		justify-content: flex-end;

		.back {
			background-color: transparent;
			border: 1px solid gray;
			color: gray;
			padding: 6px;
			border-radius: 5px;
		}

		.create {
			background-color: var(--primary);
			color: white;
			padding: 10px;
			padding: 6px;
			border-radius: 5px;
		}
	}
}
