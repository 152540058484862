.header {
	top: 0;
	z-index: 1000;
	display: flex;
	margin: 0 auto;
	position: sticky;
	padding: 8px 12px;
	max-width: 100vw;
	align-items: center;
	backdrop-filter: blur(10px);
	background-color: var(--white);
	-webkit-backdrop-filter: blur(10px);
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);

	@media (min-width: 641px) {
		padding: 12px;
	}

	@media (min-width: 1281px) {
		display: grid;
		padding: 28px auto 0;
		padding-left: calc((100vw - var(--page-size)) / 2);
		padding-right: calc((100vw - var(--page-size)) / 2);
		grid-template-columns: var(--left-sidebar-width) auto;
	}
}

.card {
	width: 100%;
	height: 90vh;
	display: flex;
	overflow: auto;
	align-items: center;
	justify-content: flex-start;

	@media only screen and (min-width: 641px) {
		height: calc(100vh - 65px);
		padding: 0px 30px;
	}

	@media only screen and (min-width: 1200px) {
		padding: 1.5em;
	}

	.left {
		width: 100%;
		display: flex;
		gap: var(--gap-md);
		align-items: center;
		flex-direction: column;
		justify-content: center;

		@media screen and (min-width: 961px) {
			width: 50%;
		}

		@media screen and (min-width: 1281px) {
			width: 45%;
		}

		.logo {
			width: 250px;

			// @media screen and (min-width: 961px) {
			// 	display: none;
			// }
		}

		.formCard {
			width: 80%;
			display: flex;
			text-align: center;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			@media screen and (min-width: 961px) {
				width: 70%;
			}

			form > h2 {
				font-weight: 550;
				font-size: var(--fs-xl);
				text-transform: none !important;
			}
		}
	}

	.right {
		display: none;

		@media screen and (min-width: 961px) {
			width: 50%;
			display: block;
			text-align: center;
		}

		.rightSide {
			width: 600px;
			height: auto;
		}

		h3 {
			font-size: var(--fs-xl);
			text-transform: none !important;
		}
	}

	h2 {
		font-weight: 600;
		line-height: 37px;
		font-size: var(--fs-display);
	}

	h3 {
		font-weight: 500;
		line-height: 30px;
		font-size: var(--fs-title);
	}

	p {
		font-weight: 550;
		line-height: 24px;
		font-size: var(--fs-head);

		@media screen and (max-width: 600px) {
			font-size: var(--fs-title);
		}
	}

	h4 {
		font-size: var(--fs-para);
	}

	h4 > span {
		color: #ff0000;
		cursor: pointer;
		margin-left: 5px;
		font-size: var(--fs-para);
	}

	p {
		font-size: var(--fs-para);
	}

	p > span {
		cursor: pointer;
		margin-left: 5px;
		color: #ff0000;
		font-size: var(--fs-para);
	}

	input {
		color: #222;
		font-size: var(--fs-para);
	}
}

.card__header {
	gap: 1rem;
	display: flex;
	align-items: center;

	img {
		width: 280px;

		@media screen and (max-width: 600px) {
			width: 230px;
		}
	}
}

.card__body__login {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	> p {
		margin: var(--gap-md) 0;
		font-size: var(--fs-md);

		@media screen and (min-width: 961px) {
			font-size: var(--fs-lg);
		}
	}
}

.card__body {
	width: 100%;
	display: flex;
	margin: 2.5rem 0;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	@media screen and (max-width: 600px) {
		margin: 1rem 0;
	}

	@media only screen and (min-width: 1025px) {
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;

		fieldset {
			width: 48%;
		}

		fieldset:nth-child(5) {
			width: 48%;
			margin: 0 auto;
		}
	}

	> p {
		margin: var(--gap-md) 0;
		font-size: var(--fs-md);

		@media screen and (min-width: 961px) {
			font-size: var(--fs-lg);
		}
	}
}

.btn,
.loadingBtn {
	width: 100%;
	border: none;
	color: #fff;
	font-size: 16px;
	cursor: pointer;
	font-weight: 500;
	margin: 2rem auto;
	line-height: 24px;
	padding: 1rem 2rem;
	border-radius: 10px;
	transition: all 0.2s ease-in-out;
	background-color: var(--primary) !important;

	&:hover {
		filter: brightness(0.9);
	}
}

.loadingBtn {
	opacity: 0.9;
	cursor: not-allowed;
}

@media screen and (max-width: 600px) {
	.btn {
		margin: 1rem 0;
		padding: 0.5rem 1rem;
	}
}

.inputField {
	height: 45px !important;
	border-radius: 10px !important;

	@media screen and (max-width: 600px) {
		height: 35px !important;
	}

	> input {
		border-radius: 8px;
		width: 100% !important;
	}
}

.getStarted {
	display: flex;
	align-items: center;
	justify-content: center;
}

.divider {
	width: 70%;
	height: 1px;
	display: flex;
	margin: var(--gap-lg) auto;
	border: 1px solid hsla(0, 0%, 0%, 0.2);

	span {
		width: 30px;
		height: auto;
		color: #222;
		display: flex;
		font-size: 14px;
		font-weight: 700;
		line-height: 18px;
		margin: -10px auto;
		align-items: center;
		justify-content: center;
		background-color: #eee;
	}
}

.forgot {
	cursor: pointer;
	font-size: 14px;
	font-weight: 500;
	line-height: 24px;
	margin: 0 0 0 auto;
	color: var(--primary);
}

.google_login {
	border: 1px solid red;
}
