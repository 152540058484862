/*------- Trending Post ---------*/

.card {
	gap: 12px;
	display: flex;
	border-radius: 10px;
	flex-direction: column;
	padding: var(--global-pad);
	max-width: var(--feature-content);
	border: 1px solid var(--second-white);
	background-color: var(--card-background);
	box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.15);
	h2 {
		font-size: 20px;
		font-weight: 800;
		overflow: hidden;
		line-height: 21px;
		text-overflow: ellipsis;
	}
	h3 {
		font-size: 14px;
		font-weight: 500;
		line-height: 17px;
	}
}

.cardItem {
	gap: 8px;
	display: grid;
	grid-template-columns: auto 70px;

	.cardContent {
		display: flex;
		flex-direction: column;

		.stats {
			gap: 8px;
			display: flex;
			align-items: center;

			.tag {
				font-size: 12px;
				color: #353a42;
				font-weight: 400;
				line-height: 15px;

				span {
					max-width: 11ch;
					font-size: inherit;
					display: inline-block;
					text-overflow: ellipsis;
				}
			}

			.time {
				font-size: 12px;
				font-weight: 500;
				line-height: 15px;
				color: var(--primary);

				span {
					font-size: inherit;
				}
			}
		}

		a > .trendpost {
			gap: 5px;
			display: grid;
			padding: 5px 0;

			.content {
				display: grid;
				p {
					overflow: hidden;
					line-height: 18px;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					font-size: var(--fs-para);
					-webkit-box-orient: vertical;
				}
			}
		}
	}

	.cardImage {
		display: flex;
		align-items: flex-end;

		a {
			height: auto;
			line-height: 0;
		}

		img {
			width: 100%;
			height: 100%;
			cursor: pointer;
			object-fit: cover;
			border-radius: 5px;
		}
	}
}

.cardHeader {
	gap: 10px;
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	justify-content: flex-start;
}

.cardIcon {
	color: var(--primary);
	height: 20px;
	width: auto;
}

.cardList {
	gap: 16px;
	display: flex;
	overflow-y: auto;
	max-height: 320px;
	flex-direction: column;
}

/*------- Trending Course ---------*/

.container2 {
	display: flex;
	gap: 20px;
	margin: 20px 0;
	border-radius: 10px;
	flex-direction: column;
	padding: var(--global-pad);
	border: 1px solid var(--second-white);
	background-color: var(--card-background);
	box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.15);
	h2 {
		font-weight: 800;
		font-size: 20px;
		line-height: 21px;
	}
}

.cardHeader2 {
	gap: 10px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.cardIcon2 {
	color: var(--primary);
	height: 20px;
	width: auto;
}

.cardList2 {
	display: flex;
	gap: 15px;
	flex-direction: column;
}

.card2 {
	border: 1px solid rgba(0, 0, 0, 0.08);
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
	border-radius: 5px;
	display: grid;
	grid-template-columns: auto 2fr;
	align-items: center;
	gap: 8px;
	min-width: 100%;
	h2 {
		font-weight: 500;
		font-size: 20px;
		line-height: 23px;
	}
	h3 {
		font-weight: 500;
		font-size: 15px;
		line-height: 18px;
	}
}

.cardImage2 {
	height: 70px;
	width: auto;
	object-fit: contain;
	border-radius: 4px;
}

.cardContent2 {
	display: flex;
	padding: 3px 0;
	flex-direction: column;
	gap: 3px;

	h2 {
		font-size: var(--fs-para);
	}

	h3 {
		font-size: var(--fs-para);
	}

	.cardViews2 {
		font-size: var(--fs-small);
	}
}

.cardCost2 {
	color: var(--primary);
}

.cardItem2 {
	display: flex;
	align-items: center;
	background-color: var(--bg-main);
}
