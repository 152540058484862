@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;550;700&display=swap');

html {
	font-family: 'Public Sans', Roboto, sans-serif;
	font-size: 62.5%;
}

*::-webkit-scrollbar {
	width: 0;
	height: 1rem;
}

*::-webkit-scrollbar-track {
	background-color: var(--primary-shade);
}

*::-webkit-scrollbar-thumb {
	background-color: var(--scrollbar);
}

*::-webkit-scrollbar-thumb:hover {
	background-color: var(--scrollbar-hover);
}

*,
*::before,
*::after,
*::placeholder {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	line-height: 20px;
	font-weight: 400;
	font-size: 1.6rem;
	margin-block-end: 0;
	margin-inline-end: 0;
	margin-block-start: 0;
	margin-inline-start: 0;
	resize: none;
	position: relative;
	font-family: inherit;
	box-sizing: border-box;
	scroll-behavior: smooth;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.25;
	font-weight: 700;
	text-transform: capitalize;
}

p,
a {
	line-height: 1.5;
}

a {
	text-decoration: none;
	text-transform: 550;
}

br {
	user-select: none;
}

img,
svg,
button,
a,
li,
input[type='submit'],
input[type='button'],
input[type='reset'] {
	user-select: none;
	-webkit-tap-highlight-color: transparent;
}

[type='text'] {
	text-transform: capitalize;
}

[type='date'] {
	text-transform: uppercase;
}

[type='password'] {
	text-transform: none;
}

[type='email'] {
	text-transform: lowercase;
}

[type='number'] {
	appearance: textfield;
}

[type='number']::-webkit-outer-spin-button,
[type='number']::-webkit-inner-spin-button {
	appearance: none;
}

button,
[type='submit'],
[type='button'],
[type='reset'] {
	font-weight: 550;
}

a,
button,
[type='submit'],
[type='button'],
[type='reset'] {
	cursor: pointer;
}

button,
[type='submit'],
[type='reset'],
textarea,
canvas {
	background-color: transparent;
}

*::placeholder {
	text-transform: unset;
	font-weight: 400;
}

a,
audio,
b,
big,
blockquote,
button,
i,
img,
input,
label,
li,
ol,
optgroup,
option,
p,
picture,
progress,
q,
small,
span,
strong,
sub,
sup,
svg,
td,
th,
u,
ul {
	display: inline-block;
}

address,
area,
article,
aside,
canvas,
caption,
code,
details,
div,
embed,
figcaption,
figure,
footer,
form,
frame,
h1,
h2,
h3,
h4,
h5,
h6,
head,
header,
hr,
iframe,
main,
map,
nav,
section,
summary,
table,
tbody,
template,
textarea,
tfoot,
thead,
video {
	display: block;
}

a {
	color: inherit;
	text-decoration: none;
	cursor: pointer;
}
