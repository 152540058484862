.logo {
	display: flex;
	cursor: pointer;
	user-select: none;
	align-items: center;
	// justify-content: center;

	h2 {
		color: var(--black);
	}

	img {
		width: 40px;
		height: 40px;
	}
}

.logo-text {
	padding: 0 2px;
	font-size: 30px;
	font-weight: 550;
	letter-spacing: 2px;
	font-family: CipherSchools_font;
	@media (max-width: 600px) {
		// display: none;
		font-size: 22px;
	}
}

.mainContainer {
	display: flex;
	width: 100%;
	align-items: center;
	@media Screen and (min-width: 800px) {
		display: grid;
		grid-template-columns: auto 90px;
	}
	@media Screen and (min-width: 1025px) {
		display: grid;
		grid-template-columns: auto var(--feature-content);
	}
	.heading {
		text-align: center;
		font-weight: 700;
		font-size: 22px;
		line-height: 27px;
		user-select: none;
		@media (max-width: 800px) {
			display: none;
		}
	}
	.personal {
		display: flex;
		gap: 12px;
		width: 100%;
		justify-content: flex-end;
		@media (min-width: 800px) {
			gap: 24px;
		}
	}

	.notification {
		transform: rotate(-15deg);
	}

	.count {
		position: absolute;
		height: 18px;
		width: 18px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 100%;
		left: 50%;
		right: 40%;
		top: 0%;
		bottom: 0%;
		font-style: normal;
		font-weight: 500;
		font-size: 11px;
		line-height: 15px;
		color: #ffffff;
		background-color: var(--primary);
	}
}

.list {
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 0 8px rgba(10, 30, 50, 0.2);
	font-family: 'open sans', sans-serif;
	color: rgba(10, 30, 50, 0.8);
	display: flex;
	flex-direction: column;
	height: 100%;
	list-style: none;
	margin: 10px 0 2px;
	overflow: hidden;
	padding: 10px 2px;
	transition: 1s ease;
	width: 100%;
}

.dropdown {
	bottom: auto;
	position: absolute;
	width: max-content;
	z-index: 10;
}

.avatar {
	height: 40px;
	width: 40px;
	overflow: hidden;
	border-radius: 50%;
	box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.15);

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@media (max-width: 600px) {
		display: none;
	}
}

.link {
	font-size: 18px;
	line-height: 27px;
	margin-top: 4px;
}

.item {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	width: 100%;
	font-size: medium;
	font-weight: 400;
	color: #756f6f;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	&:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
}
