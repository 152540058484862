:root {
	--fw-normal: 400;
	--fw-light: 500;
	--fw-medium: 550;
	--fw-bold: 700;
	// === === === == == === === ==== //
	--fs-xxs: 10px;
	--fs-xs: 12px;
	--fs-sm: 14px;
	--fs-md: 16px;
	--fs-lg: 18px;
	--fs-xl: 20px;
	--fs-2xl: 24px;
	--fs-3xl: 30px;
	--fs-4xl: 36px;
	--fs-5xl: 48px;
	--fs-6xl: 60px;
	// === === === == == === === ==== //
	--fs-display: 2.3rem;
	--fs-large: 2rem;
	--fs-head: 1.8rem;
	--fs-title: 1.65rem;
	--fs-para: 1.5rem;
	--fs-small: 1.3rem;
	--fs-text: 1.1rem;
	// === == == == == == == == == === //
	--radius-2xl: 2rem;
	--radius-xl: 1.625rem;
	--radius-lg: 1.25rem;
	--radius-md: 0.8rem;
	--radius-sm: 0.5rem;
	// === == == == == == == == == === //
	--pad-card: 2rem;
	--padding: 2rem;
	--gap-xl: 30px;
	--gap-lg: 20px;
	--gap-md: 10px;
	--gap-sm: 5px;
	--gap-xsm: 3px;
	--gap-side: 150px;
	--gap-section: 37px;
	// === == == == == == == == == === //
	// --primary: rgba(243, 145, 46, 1);
	--bg-background: #fafafa;
	--card-background: #fff;
	--primary: rgb(244, 80, 70);
	--primary-light: rgba(244, 80, 70, 0.7);
	--primary-shade: rgba(255, 89, 89, 0.1);
	--primary-text: rgba(255, 255, 255, 0.95);
	// === == == == == == == == == === //
	--aqua: rgba(0, 215, 215, 1);
	--aqua-shade: rgba(0, 215, 215, 0.1);
	--aqua-text: rgba(255, 255, 255, 0.95);
	// === == == == == == == == == === //
	--blue: rgba(25, 140, 255, 1);
	--blue-shade: rgba(25, 140, 255, 0.1);
	--blue-text: rgba(255, 255, 255, 0.95);
	// === == == == == == == == == === //
	--green: rgba(0, 190, 0, 1);
	--green-shade: rgba(0, 190, 0, 0.1);
	--green-text: rgba(255, 255, 255, 0.95);
	// === == == == == == == == == === //
	--black: rgb(53, 56, 57);
	--black-shade: rgba(8, 16, 16, 0.06);
	--black-text: rgba(255, 255, 255, 0.95);
	// === == == == == == == == == === //
	--white: rgba(255, 255, 255, 1);
	--white-shade: rgba(255, 255, 255, 0.1);
	--white-text: rgba(8, 16, 16, 0.95);
	// === == == == == == == == == === //
	--red: rgba(255, 60, 0.1);
	--red-shade: rgba(255, 60, 0.1);
	--red-text: rgba(255, 255, 255, 0.95);
	// === == == == == == == == == === //
	--yellow: rgba(255, 215, 0, 1);
	--yellow-shade: rgba(255, 215, 0, 0.1);
	--yellow-text: rgba(8, 16, 16, 0.95);
	// === == == == == == == == == === //
	--orange: rgba(255, 150, 0, 1);
	--orange-shade: rgba(255, 150, 0, 0.1);
	--orange-text: rgba(255, 255, 255, 0.95);
	// === == == == == == == == == === //
	--slateblue: rgba(120, 60, 255, 1);
	--slateblue-shade: rgba(120, 60, 255, 0.1);
	--slateblue-text: rgba(255, 255, 255, 0.95);
	// === == == == == == == == == === //
	--violet: rgba(180, 60, 215, 1);
	--violet-shade: rgba(180, 60, 215, 0.1);
	--violet-text: rgba(255, 255, 255, 0.95);
	// === == == == == == == == == === //
	--shadow: 1px 2px 1.25rem rgba(8, 16, 16, 0.05);
	--border: rgba(0, 0, 0, 0.15);
	--feature-content: 350px;
}

.light {
	--brand-color: #f3912e;
	--brand-color-text: rgba(255, 255, 255, 0.95);
	--brand-color-shade: rgba(243, 145, 46, 0.1);
	// === == == == == == == == == === //
	--bg-main: white;
	--bg-sub: #f2f5fa;
	--second-white: rgb(247, 249, 249);
	--white-shade2: rgb(226, 226, 226);
	// === == == == == == == == == === //
	--table-header: #ffd6d6;
	--table-header-text: rgba(8, 16, 16, 0.95);
	// === == == == == == == == == === //
	--skeleton: rgba(8, 16, 16, 0.1);
	--scrollbar: rgba(8, 16, 16, 0.15);
	--scrollbar-hover: rgba(8, 16, 16, 0.25);
	// === == == == == == == == == === //
	--heading: rgba(8, 15, 15, 0.85);
	--sub-heading: rgba(8, 15, 15, 0.7);
	--paragraph: rgba(8, 15, 15, 0.75);
	--second-button: rgba(8, 15, 15, 0.85);
	--second-button-text: rgba(255, 255, 255, 0.95);
	// === == == == == == == == == === //
	--border-color: rgba(10, 30, 50, 0.1);
	--shadow1: 0 0 12px rgba(10, 30, 50, 0.15);
	--shadow2: 0 0 15px rgba(10, 30, 50, 0.2);
	--shadow3: 0 0 20px rgba(10, 30, 50, 0.25);
}

.dark {
	--brand-color: #f3912e;
	--brand-color-text: rgba(255, 255, 255, 0.95);
	--brand-color-shade: rgba(243, 145, 46, 0.1);
	// === == == == == == == == == === //
	--bg-main: #232828;
	--bg-sub: #333838;
	--second-white: #15181e;
	// === == == == == == == == == === //
	--table-header: #253545;
	--table-header-text: rgba(255, 255, 255, 0.95);
	// === == == == == == == == == === //
	--skeleton: rgba(255, 255, 255, 0.1);
	--scrollbar: rgba(255, 255, 255, 0.15);
	--scrollbar-hover: rgba(255, 255, 255, 0.25);
	// === == == == == == == == == === //
	--heading: rgba(255, 255, 255, 0.85);
	--sub-heading: rgba(255, 255, 255, 0.7);
	--paragraph: rgba(255, 255, 255, 0.75);
	--second-button: rgba(255, 255, 255, 0.95);
	--second-button-text: rgba(8, 15, 15, 0.95);
	// === == == == == == == == == === //
	--border: rgba(255, 255, 255, 0.25);
	--border-color: rgba(255, 255, 255, 0.1);
	--shadow1: 0 0 12px rgba(255, 255, 255, 0.15);
	--shadow2: 0 0 15px rgba(255, 255, 255, 0.2);
	--shadow3: 0 0 20px rgba(255, 255, 255, 0.25);
}
