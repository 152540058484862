.header {
	display: grid;
	top: 0;
	z-index: 99;
	position: sticky;
	align-items: center;
	padding: 10px var(--global-pad);
	background-color: var(--white);
	grid-template-columns: 1fr auto;
	border-bottom: 1px solid rgba(0, 0, 0, 0.25);

	.main {
		display: grid;
		gap: 10px;
		width: auto;
		align-items: center;
		justify-content: flex-start;
		grid-template-columns: 50px 1fr;

		.join {
			border-radius: 10px;
			padding: 5px 10px;
		}
	}

	.chatHome {
		display: grid;
		width: 20px;
		justify-content: center;
	}

	.description {
		display: flex;
		width: 100px;
		align-items: center;
		justify-content: space-between;
	}

	h2 {
		gap: 5px;
		font-weight: 600;
		line-height: 30px;
		margin-bottom: 3px;
		text-align: start;
		font-size: var(--fs-display);
	}
	h3 {
		font-weight: 400;
		font-size: 15px;
		line-height: 18px;
		color: rgba(0, 0, 0, 0.6);
	}

	svg {
		cursor: pointer;
	}

	.lock {
		position: absolute;
		z-index: 2;
		bottom: 0;
		left: 30px;
		width: 28px;
		height: 28px;
		padding: 3px;
		border-radius: 50%;
		color: var(--primary);
		background-color: var(--white);
	}

	@media (max-width: 600px) {
		h2 {
			font-weight: 600;
			line-height: 20px;
			font-size: var(--title);
		}
		h3 {
			font-size: 14px;
			line-height: 16px;
		}
	}
}

.desctiption-header {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	position: relative;
	padding: 15px 0px;
	top: 0;
	z-index: 99;
	position: sticky;
	align-items: center;
	background-color: var(--white);
	grid-template-columns: 1fr auto;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);

	.back {
		position: absolute;
		top: 15px;
		left: 10px;

		.icon {
			width: 25px;
			height: 25px;
		}
	}

	.description-main {
		width: 80%;
		margin-top: 20px;
		margin: auto;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;

		.avatar {
			position: relative;
			.camera {
				position: absolute;
				bottom: 0;
				right: 0;
				cursor: pointer;
				input {
					display: none;
				}
				.icon {
					width: 25px;
					height: 25px;
					z-index: 1000;
					color: var(--primary);
				}
			}
		}
		.info {
			display: flex;
			margin-top: 10px;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			h2 {
				width: 90%;
				line-clamp: 2;
				text-align: center;
				-webkit-line-clamp: 2;
				font-size: var(--fs-large);
			}
			p,
			h3 {
				width: 100%;
				text-align: center;
				font-size: var(--fs-para);
			}
			p {
				opacity: 0.4;
			}
			h3 {
				opacity: 0.7;
			}
			.edit-info {
				width: 100%;
				margin-bottom: 5px;
				border-bottom: 1px solid grey;
				input {
					// padding-bottom: 4px;
				}
				button {
					// position: absolute;
					// right: 0;
				}
			}
		}
	}
}

.secondaryHeader {
	display: grid;
	width: 100%;
	height: 45px;
	background-color: var(--white);
	grid-template-columns: 1fr 1fr;
	border-bottom: 1px solid rgba(0, 0, 0, 0.25);

	.listItem {
		display: flex;
		padding: 3px 0;
		cursor: pointer;
		font-weight: 550;
		align-items: center;
		justify-content: center;
		font-size: var(--fs-title);

		&:hover {
			background-color: var(--black-shade);
		}
	}

	.active {
		font-weight: 600;
		color: var(--primary);
		background-color: #f5f5f5;
	}

	.badge {
		display: flex;
		width: 20px;
		height: 20px;
		font-size: 12px;
		color: var(--white);
		border-radius: 50%;
		position: absolute;
		align-items: center;
		justify-content: center;
		transform: translate(40px, -0);
		background-color: var(--primary);
	}
}

.info {
	cursor: pointer;
}

.info2 {
	width: 100%;
	cursor: inherit;
}

.back {
	font-weight: 400;
	font-size: var(--fs-head);
	line-height: 28px;
	color: #353a42;
	margin-left: auto;
	display: flex;
	align-items: center;
}

.dots {
	.list {
		background-color: #fff;
		border-radius: 5px;
		box-shadow: 0 0 8px rgba(10, 30, 50, 0.2);
		font-family: 'open sans', sans-serif;
		color: rgba(10, 30, 50, 0.8);
		display: flex;
		flex-direction: column;
		list-style: none;
		margin: 10px 0 2px;
		overflow: hidden;
		padding: 10px 2px;
		transition: 1s ease;
		width: 100%;
	}

	.options__container {
		bottom: auto;
		right: 0;
		display: none;
		opacity: 0;
		position: absolute;
		visibility: hidden;
		width: auto;
		padding: 4px 4px;
		z-index: 10;
	}

	.showdots {
		display: flex;
		opacity: 1;
		visibility: visible;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}

	.item__options {
		display: flex;
		gap: 20px;
		width: 100%;
		padding: 5px 15px;
		border-radius: 4px;
		align-items: center;
	}

	.item__options:hover {
		cursor: pointer;
		background-color: #f5f5f5;
	}
}
