.input__marker {
	background-color: var(--white);
	height: 100px;
	color: var(--white-text);
	border-radius: 4px;
	box-shadow: 0 2px 5px #585858;
	/* display: block; */
	display: none;
	padding: 4px 6px;
	position: absolute;
	transform: translate(5%, -70%);
	transition: top 0.1s ease 0s, left 0.1s ease 0s;
	/* white-space: nowrap; */
	/* width: auto; */
	z-index: 1000000;
}

.images-cont .swiper-button-prev,
.swiper-button-next {
	background-color: var(--white) !important;
}
