.dropdown {
	width: 100%;
}

.dropdownHeader {
	padding: 10px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	border-radius: 8px;
	background-color: var(--bg-background);
}

.dropdownList {
	display: flex;
	width: 100%;
	margin: auto;
	z-index: 1000;
	overflow-y: auto;
	max-height: 160px;
	position: absolute;
	flex-direction: column;
	border: 1px solid rgba(0, 0, 0, 0.2);
	background-color: var(--bg-background);
}

.dropdownListItem {
	padding: 5px 10px;
	cursor: pointer;
}

.dropdownListItem:hover {
	background: #e6e6e6;
}

.arrow {
	margin-left: 10px;
}
