.cardItem {
	display: flex;
	gap: 10px;
	width: 100%;
	cursor: pointer;
	align-items: center;
	justify-content: space-between;

	.actions {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}

	.item {
		display: grid;
		gap: 10px;
		width: 100%;
		align-items: center;
		grid-template-columns: auto 3fr;
		span {
			font-weight: 500;
			line-height: 14px;
			white-space: pre-wrap;
			font-size: var(--fs-text);
		}
		h4 {
			font-weight: 500;
			line-height: 21px;
			font-size: var(--fs-title);
		}
	}

	.info {
		display: flex;
		width: 100%;
		overflow: hidden;
		justify-content: center;
		flex-direction: column;

		h3 {
			font-weight: 400;
			font-size: 15px;
			line-height: 18px;
			text-transform: none;
			color: rgba(0, 0, 0, 0.6);
		}

		.unread {
			font-weight: 550;
			color: var(--primary);
		}

		span > span {
			transform: translateY(3px);
		}
	}

	.btn {
		gap: 4px;
		width: auto;
		padding: 5px 5px;
		border-radius: 5px;
		margin-left: auto;
		font-size: var(--fs-small);

		svg {
			width: 18px;
			height: 18px;
		}
	}

	> div {
		display: flex;
		align-items: center;
	}

	.lock {
		position: absolute;
		z-index: 2;
		bottom: 0;
		left: 25px;
		width: 22px;
		height: 22px;
		padding: 3px;
		border-radius: 50%;
		color: var(--primary);
		background-color: var(--white);
	}
	.adminText {
		color: var(--primary);
		font-weight: 550;
		opacity: 0.8;
	}
}

.time {
	font-weight: 600;
	font-size: 16px;
	color: var(--primary);
}

.icon {
	display: flex;
	border-radius: 50%;
}

.description {
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
}

.dots {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	.list {
		background-color: #fff;
		border-radius: 5px;
		box-shadow: 0 0 8px rgba(10, 30, 50, 0.2);
		font-family: 'open sans', sans-serif;
		color: rgba(10, 30, 50, 0.8);
		display: flex;
		flex-direction: column;
		list-style: none;
		margin: 10px 0 2px;
		overflow: hidden;
		padding: 10px 2px;
		transition: 1s ease;
		width: 100%;
	}

	.options__container {
		bottom: auto;
		right: 0;
		display: none;
		opacity: 0;
		position: absolute;
		visibility: hidden;
		width: auto;
		padding: 4px 4px;
		z-index: 10;
	}

	.showdots {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		opacity: 1;
		visibility: visible;
	}

	.item__options {
		display: flex;
		align-items: center;
		gap: 20px;
		width: 100%;
		padding: 5px 15px;
		border-radius: 4px;
	}

	.item__options:hover {
		cursor: pointer;
		background-color: #f5f5f5;
	}
}
