.container {
	display: grid;
	width: 100%;
	gap: 10px;
	overflow-x: hidden;
	overflow-y: auto;
	padding-bottom: 60px;
	scroll-behavior: smooth;

	.pagebar {
		display: flex;
		margin-top: 20px;
		z-index: 1;
		justify-content: center;
	}

	@media (min-width: 941px) and (orientation: landscape) {
		padding-bottom: 0px;
	}
	@media screen and (min-width: 941px) {
		gap: 20px;
	}
}

// styles to fix the scroll bottom bug
.container * {
	overflow-anchor: none;
}

.anchor {
	overflow-anchor: auto;
	height: 1px;
}

.floater {
	width: 35px;
	right: 15px;
	height: 35px;
	bottom: 60px;
	display: none;
	position: absolute;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	transition: display 0.3s ease-in-out;
	background-color: var(--second-white);
	-webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.44);
	-moz-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.44);
	box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.44);

	.floaterNumber {
		position: absolute;
		padding: 4px;
		background-color: var(--primary);
		color: var(--primary-text);
		border-radius: 50%;
		top: 0;
		left: 0;
	}

	.icon {
		width: 30px;
		height: 30px;
		color: var(--primary);
	}
}

.showFloater {
	display: flex;
}

.loadingSection {
	padding: 20px 0;
}
