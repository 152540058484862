.button {
	display: flex;
	gap: 12px;
	cursor: pointer;
	font-weight: 500;
	font-size: var(--fs-small);
	line-height: 27px;
	padding: 8px 24px;
	color: var(--white);
	border-radius: 15px;
	align-items: center;
	justify-content: center;
	background-color: var(--primary);
	box-shadow: 3px 2px 8px rgba(0, 0, 0, 0.15);

	@media (max-width: 600px) {
		font-size: var(--fs-small);
		line-height: 16px;
		padding: 6px 16px;
	}
}

:disabled {
	cursor: not-allowed;
}

.outlineStyle {
	color: black;
	box-shadow: none;
	background-color: var(--second-white);

	&:hover {
		background-color: transparent;
	}
}
