.dropdown {
	position: relative;
	display: inline-block;

	.dropdownToggle {
		display: flex;
		width: 100%;
		cursor: pointer;
		padding: 5px 15px;
		align-items: center;
		border-radius: 1000px;
		color: var(--black);
		text-transform: capitalize;
		justify-content: space-between;
		border: 1px solid var(--black);

		span {
			font-weight: 550;
		}

		svg {
			width: 20px;
			height: 20px;
		}
	}

	.hidden {
		display: none;
	}

	:disabled {
		cursor: not-allowed;
	}

	.dropdownMenu {
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 1;
		min-width: 100%;
		padding: 8px 0;
		margin: 2px 0 0;
		border-radius: 8px;
		list-style-type: none;
		border: 1px solid #ccc;
		background-color: #fdfdfd;
		box-shadow: 3px 3px 5px #ccc;

		li {
			width: 100%;

			.dropdownItem {
				display: grid;
				border: none;
				width: 100%;
				gap: 5px;
				cursor: pointer;
				padding: 5px 10px;
				color: var(--fc-para);
				text-decoration: none;
				justify-content: flex-start;
				grid-template-columns: 20px auto;
				background-color: transparent;

				&:hover,
				&:focus {
					background-color: #eaeaea;
				}

				span {
					text-transform: capitalize;
				}
			}
		}
	}
}
