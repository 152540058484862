.boradcast {
	width: 100%;
	// height: 50vh;
	padding: 4px;

	.warningText {
		font-size: var(--fs-small) !important;
		color: rgb(255, 96, 96) !important;
	}

	.message {
		width: 100%;
		margin-bottom: 10px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;

		label {
			font-size: var(--fs-head);
			margin-bottom: 4px;
		}

		.images {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 5px;

			.singleImage {
				width: 60px;
				height: 40px;
				position: relative;

				.icon {
					width: 15px;
					height: 15px;
					position: absolute;
					top: 0;
					right: 0;
					color: var(--primary);
					cursor: pointer;
				}
				img {
					height: 100%;
					width: 100%;
					border-radius: 3px;
				}
			}
		}

		.input {
			width: 100%;
			height: 300px;
			padding: 5px;
			border-radius: 4px;
			border: 1px solid lightgrey;
			position: relative;

			.inputField {
				width: 100%;
				height: 90%;
			}

			.icons {
				width: 100%;
				margin-top: 4px;
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				// align-items: center;
				gap: 6px;

				label {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
				}
				.icon {
					width: 23px;
					height: 23px;
					color: var(--primary);
					cursor: pointer;
				}
				.emoji {
					em-emoji-picker {
						top: unset;
					}
					svg {
						width: 23px;
						height: 23px;
						border-radius: 50%;
						color: var(--primary) !important;

						&:hover {
							background-color: var(--black-shade);
						}
					}
				}
			}
			@media only screen and (min-width: 640px) {
				height: 130px;
				.inputField {
					height: 80%;
				}
			}
		}
	}

	.communities {
		p {
			font-size: var(--fs-head);
		}
	}

	.footer {
		width: 100%;
		margin-top: 15px;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.broadcastBtn {
			padding: 10px;
			border: 1px solid var(--primary);
			color: var(--primary);
			border-radius: 8px;
		}
	}
}
