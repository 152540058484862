.container {
	width: 300px;
	display: flex;
	gap: var(--gap-md);
	flex-direction: column;

	@media screen and (min-width: 641px) {
		width: 400px;
	}

	> h3 {
		font-size: var(--fs-md);

		@media screen and (min-width: 641px) {
			font-size: var(--fs-lg);
		}
		@media screen and (min-width: 1281px) {
			font-size: var(--fs-xl);
		}
	}

	> div {
		.input {
			width: 100%;
			border-radius: 4px;
			padding: var(--gap-md);
			font-size: var(--fs-sm);
			border: 1px solid var(--border);
			background-color: var(--second-white);

			@media screen and (min-width: 641px) {
				font-size: var(--fs-md);
			}
			@media screen and (min-width: 1281px) {
				font-size: var(--fs-lg);
			}
		}

		.submit-btn {
			border-radius: 4px;
			color: var(--white);
			padding: var(--gap-sm);
			font-size: var(--fs-sm);
			background-color: var(--primary);

			@media screen and (min-width: 641px) {
				padding: var(--gap-md);
				font-size: var(--fs-md);
			}
			@media screen and (min-width: 1281px) {
				font-size: var(--fs-lg);
			}
		}
	}

	.sf-btns-cont {
		text-align: end;
	}
}
