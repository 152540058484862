.header {
	display: flex;
	position: sticky;
	top: 0;
	z-index: 1000;
	margin: 0 auto;
	align-items: center;
	padding: 8px 12px;
	max-width: 100vw;
	backdrop-filter: blur(10px);
	background-color: var(--white);
	-webkit-backdrop-filter: blur(10px);
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);

	@media (min-width: 641px) {
		padding: 12px;
	}

	@media (min-width: 1281px) {
		display: grid;
		padding: 28px auto 0;
		padding-left: calc((100vw - var(--page-size)) / 2);
		padding-right: calc((100vw - var(--page-size)) / 2);
		grid-template-columns: var(--left-sidebar-width) auto;
	}
}

.show {
	display: none;
	@media screen and (min-width: 641px) {
		display: flex;
	}
}

.main {
	@media (min-width: 481px) and (orientation: landscape) {
		margin: 0px auto;
	}

	@media (min-width: 641px) {
		display: grid;
		max-width: var(--page-size);
		grid-template-columns: var(--left-sidebar-width) auto;
	}

	@media (min-width: 1025px) {
		grid-template-columns: var(--left-sidebar-width) auto;
	}

	> .sidebar {
		display: none;
		top: 0;
		width: 100%;
		padding: 0 15px;
		padding-top: 60px;
		position: sticky;
		overflow-y: auto;
		max-height: var(--max-height);

		@media (min-width: 641px) and (orientation: portrait) {
			display: block;
		}

		@media (min-width: 961px) and (orientation: landscape) {
			display: block;
		}
	}
}

.container {
	display: flex;
	gap: 10px;
	width: 100%;
	overflow-y: hidden;
	height: var(--max-height);

	.content {
		top: 0;
		width: 100%;
		margin: 0px;
		overflow: auto;
		position: sticky;
		margin-bottom: 0;
		background-size: cover;
		max-width: var(--content);
		background-position: center;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		background-color: var(--card-background);

		@media screen and (min-width: 641px) {
			margin-top: 10px;
		}

		> section {
			--community-header: 116px;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;

			> article {
				--community-header: 129px;
				top: 0;
				width: 100%;
				margin: 0 auto;
				overflow-y: auto;
				position: sticky;
				padding-bottom: 50px;
				max-width: var(--content);
				height: calc(var(--max-height) - var(--community-header));

				@media (max-width: 641px) {
					--community-header: 171px;
					font-size: var(--fs-head);
				}
			}

			> section {
				position: absolute;
				border-radius: 5px;
				align-items: flex-end;
			}

			@media (min-width: 641px) {
				padding-bottom: 0px;
				> section {
					bottom: 0px;
					border-radius: 0px;
				}
			}
			@media (min-width: 941px) {
				padding-bottom: 0px;
				> section {
					bottom: 0px;
				}
			}

			@media (min-width: 1024px) {
				padding-bottom: 0px;
				> section {
					bottom: 0px;
				}
			}
			> img {
				height: 100%;
				width: 100%;
				opacity: 0.34;
				object-fit: cover;
				position: absolute;
			}
		}
	}

	.sidebar {
		display: none;
		width: 100%;
		top: 0;
		overflow-y: auto;
		position: sticky;
		max-height: var(--max-height);
		max-width: var(--feature-content);
		@media (min-width: 1025px) {
			display: block;
		}
	}
}

.line {
	border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}
