.emojiPicker {
	display: none;

	em-emoji-picker {
		position: absolute;
		margin-top: 5px;
		z-index: 1;
		box-shadow: 2px 2px 10px 2px lightgray;
	}

	@media screen and (min-width: 941px) {
		display: flex;
	}

	.actions {
		display: flex;
		width: auto;
		position: relative;
	}

	.icon {
		height: 24px;
		width: 24px;
		cursor: pointer;
	}
}

.bottom-right {
	em-emoji-picker {
		top: 100%;
		left: 100%;
	}
}

.bottom-left {
	em-emoji-picker {
		top: 100%;
		right: 100%;
	}
}

.top-right {
	em-emoji-picker {
		bottom: 100%;
		left: 100%;
	}
}

.top-left {
	em-emoji-picker {
		bottom: 100%;
		right: 100%;
	}
}
