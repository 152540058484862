@keyframes shine {
	0% {
		opacity: 0.2;
		transition-property: left, top, opacity;
		transition-duration: 0.7s, 0.7s, 0.2s;
		transition-timing-function: ease;
	}
	50% {
		opacity: 0.1;
		transition-property: left, top, opacity;
	}
	100% {
		opacity: 0.2;
		transition-property: left, top, opacity;
	}
}

.follow {
	display: flex;
	gap: 10px;
	margin: 8px;
	width: inherit;
	align-items: center;
	margin-bottom: 10px;
}

.skeleton {
	position: relative;
	overflow: hidden;
	background-color: rgba(8, 15, 15, 0.85);
	border-radius: 4px;
	animation: shine 2s infinite;
}

.sidebar-follower {
	width: 85%;
	height: 45px;
	margin: 5px auto;
	border-radius: 15px;
}

.avatar {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

.avatar-xl {
	min-width: 50px;
	height: 50px;
	border-radius: 50%;

	@media screen and (min-width: 941px) {
		min-width: 70px;
		height: 70px;
	}
}

.avatar-xxl {
	width: 80px;
	height: 80px;
	border-radius: 50%;
}

.username {
	width: 100px;
	height: 15px;
	margin: 5px 0;
}

.name {
	width: 140px;
	height: 20px;
	margin: 5px 0;
}

.button {
	width: 120px;
	height: 30px;
	margin: 5px 0 5px auto;
}

.text {
	width: 100%;
	height: 15px;
	margin: 10px 0;
}

.half-text {
	width: 50%;
	height: 15px;
	margin: 10px 0;
}

.title-text {
	width: 50%;
	height: 30px;
	margin: 15px 0;
}

.community-head-text {
	width: 50%;
	height: 20px;
	margin: 3px 0;

	@media screen and (min-width: 941px) {
		height: 30px;
		margin: 5px 0;
	}
}

.image {
	width: 100%;
	height: 340px;
	margin: 10px 0;
}

.action-button {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	margin: 0 5px;
}

.chat {
	display: flex;
	gap: 10px;
	width: 100%;
	margin-bottom: 10px;
	flex-direction: column;
	align-items: flex-start;

	.left {
		display: flex;
		gap: 10px;
	}

	.right {
		display: flex;
		gap: 10px;
		width: 100%;
		flex-direction: row-reverse;
	}
}

.mini-post {
	width: 120px;
	height: 40px;

	@media (min-width: 641px) {
		width: 160px;
		height: 50px;
	}
}

.chat-avatar {
	width: 30px;
	height: 30px;
	border-radius: 50%;

	@media (min-width: 641px) {
		width: 35px;
		height: 35px;
	}
}

.course {
	width: 100%;
	height: 74px;
	margin: -1px 0;
}

.ChatHeader {
	display: flex;
	gap: 5px;
	flex-direction: column;

	.follow__header__info {
		width: 100%;
	}

	.header {
		display: flex;
		padding: 8px;
		gap: 5px;
	}
}

.InfoHeader {
	display: flex;
	gap: 5px;

	.follow__header__info {
		width: 100%;
	}

	.header {
		display: flex;
		padding: 8px;
		gap: 5px;
	}
}
