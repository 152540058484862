:root {
	--search-header: 100px;
}

@media Screen and (max-width: 600px) {
	:root {
		--seach-header: 75px;
	}
}

.topHeader {
	padding: 0 10px;
	border-radius: 10px;
	background-color: var(--card-background);

	.searchbar {
		display: flex;
		height: 45px;
		padding: 10px;
		border-radius: 10px;
	}

	@media screen and (min-width: 941px) {
		padding: 0px;
	}
}

.body {
	border-radius: 5px;
	padding: var(--global-pad);
	background-color: var(--card-background);

	.communityHeading {
		width: 100%;
		display: flex;
		margin-bottom: 15px;
		align-items: center;
		justify-content: space-between;

		.icon {
			width: 27px;
			height: 27px;
			cursor: pointer;

			@media only screen and (min-width: 1024px) {
				display: none;
			}
		}
		h3 {
			padding: 0;
			font-weight: 550;
			font-size: var(--fs-title);
		}
	}
}

.container {
	gap: 15px;
	padding: 3px 0;
	padding-bottom: 40px;
	max-height: calc(var(--max-height) - var(--search-header)) !important;
}

.listItem {
	grid-template-columns: auto 30px !important;
	align-items: center;
	padding: 0 2px;
	a {
		display: grid;
		grid-template-columns: 40px auto !important;
		img {
			width: 40px;
			height: 40px;
		}

		h4 {
			font-size: var(--fs-title) !important;
		}
	}
}

.item {
	h4 {
		font-size: var(--fs-title) !important;
	}
}

.pinicon {
	display: flex;
	width: 100%;
	align-items: center;
	flex-direction: column;

	svg {
		width: 70px;
	}

	h6 {
		color: var(--white-text);
	}
}

.listHeading {
	display: flex;
	padding: 0 5px;
	align-items: center;
	justify-content: space-between;

	svg {
		cursor: pointer;
	}
}

.communityList {
	display: grid;
	gap: 10px;
}
