.navCard {
	background: var(--white);
	border: 1px solid rgba(0, 0, 0, 0.15);
	box-shadow: 0px 0px 110px rgba(0, 0, 0, 0.1);

	h2 {
		font-weight: 400;
		line-height: 14px;
		font-size: var(--fs-text);
	}

	h3 {
		font-weight: 500;
		line-height: 21px;
		color: #353a42;
		font-size: var(--fs-para);
	}

	/* smartphone portrait */
	@media only screen and (min-width: 320px) and (orientation: portrait) {
		/* hi */
	}

	/* smartphone landscape */
	@media only screen and (min-width: 481px) and (orientation: landscape) {
		/* hi2 */
	}

	/* portrait tablets */
	@media only screen and (min-width: 641px) and (orientation: portrait) {
		/* hi3 */
	}

	/* Tablet landscape */
	@media only screen and (min-width: 961px) and (orientation: landscape) {
	}

	@media screen and (min-width: 1025px) {
	}

	/* big laptop screens */
	@media only screen and (min-width: 1281px) {
		/* hi7 */
	}
}

.nav {
	display: flex;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 60px;
	z-index: 1299;
	position: fixed;

	.navCard {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		background: var(--white);
		border: 1px solid rgba(0, 0, 0, 0.15);
		box-shadow: 0px 0px 110px rgba(0, 0, 0, 0.1);
	}

	@media (min-width: 641px) and (orientation: portrait) {
		display: none !important;
	}

	@media (min-width: 961px) and (orientation: landscape) {
		display: none !important;
	}
}
