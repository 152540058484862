.ndpCont {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	span {
		height: auto !important;
		margin: 0 auto;
		width: 70% !important;
	}

	h1 {
		display: flex;
		margin: 0 auto;
		margin-top: 2em;
		font-size: 20px;
		font-weight: 550;
		line-height: 1.25;
		text-transform: none;
		color: var(--primary);
	}
}

.ndpText {
	font-weight: bold;
	font-size: 50px;
	opacity: 0.9;
	font-size: 2em;
	-webkit-line-clamp: 2;
	color: var(--text-color);
}

.p404SvgCont {
	display: flex;
	width: auto;
	height: auto;
}

.p404SvgCont > img {
	width: 100%;
	height: 350px;
}
