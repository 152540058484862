@use './reset.scss';
@use './theme.scss';
@use './default.scss';
@use './utilities.scss';

:root {
	--page-size: 100%;
	--content: 600px;
	--global-pad: 15px;
	--max-height: calc(100vh - 55px);
	background-color: var(--bg-background);
}

/* smartphone landscape */
@media only screen and (min-width: 481px) and (orientation: landscape) {
	/* hi3 */
	:root {
		--page-size: 600px;
	}
}
/* portrait tablets */
@media only screen and (min-width: 641px) and (orientation: portrait) {
	/* hi4 */
	:root {
		--page-size: 961px;
		--content: 600px;
		--global-pad: 18px;
		--feature-content: 350px;
		--left-sidebar-width: 300px;
		--max-height: calc(100vh - 66px);
	}
}
/* Tablet landscape */
@media only screen and (min-width: 961px) and (orientation: landscape) {
	/* hi5 */
	:root {
		--page-size: 1025px;
		--content: 600px;
		--global-pad: 20px;
		--feature-content: 350px;
		--left-sidebar-width: 320px;
		--max-height: calc(100vh - 66px);
	}
}
/* medium laptop screens */
@media screen and (min-width: 1025px) {
	/* hi6 */
	:root {
		--page-size: 1281px;
		--content: 620px;
		--feature-content: 310px;
		--left-sidebar-width: 310px;
		--max-height: calc(100vh - 66px);
	}
}
/* big laptop screens */
@media only screen and (min-width: 1281px) {
	/* hi7 */
	:root {
		--page-size: 1298px;
		--content: 620px;
		--global-pad: 22px;
		--feature-content: 350px;
		--max-height: calc(100vh - 66px);
	}
}
